<template>
  <h4>CustomReportView</h4>
</template>

<script>
import InitCustomReportViewInteractor from "@/business/custom-report-view/init-custom-report-view";
import CustomReportViewScreenController from "@/adapters/controllers/screen-custom-report-view";

export default {
  screen_name: "custom-report-view",
  data() {
    return {
      controller: null,
      interactors: {
        initCustomReportView: null
      },
      data: null
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(CustomReportViewScreenController);

    //{ INTERACTORS
    this.interactors.initCustomReportView = this.$injector.get(
      InitCustomReportViewInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
  },
  mounted() {},
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    }
  }
};
</script>

<style lang="scss"></style>
